<script setup lang="ts">
import { cva } from 'class-variance-authority'
import { computed, type PropType } from 'vue'
import { B2BBadgeType, Size, BadgeColor } from '../../../interfaces/UI'

const props = defineProps({
    color: {
        type: String as PropType<BadgeColor>,
        default: BadgeColor.GRAY
    },
    size: {
        type: String as PropType<Size>,
        default: Size.MD
    },
    type: {
        type: String as PropType<B2BBadgeType>,
        default: B2BBadgeType.PILL
    },
    indicator: {
        type: String as PropType<BadgeColor | null>,
        default: null
    },
    customTextSize: {
        type: String,
        default: undefined
    }
})

const customStyles = computed(() =>
    cva('flex items-center justify-center gap-1.5 font-medium border w-max', {
        variants: {
            type: {
                badge: 'rounded-lg',
                pill: 'rounded-2xl'
            },
            color: {
                gray: 'text-slate-700 border-slate-200 bg-slate-50',
                purple: 'text-purple-700 border-purple-200 bg-purple-50',
                turquoise: 'text-emerald-700 border-green-200 bg-teal-50',
                teal: 'text-teal-700 border-teal-200 bg-teal-50',
                cyan: 'text-cyan-700 border-cyan-200 bg-cyan-50',
                blue: 'text-blue-700 border-blue-200 bg-blue-50',
                yellow: 'text-yellow-700 border-yellow-200 bg-yellow-50',
                orange: 'text-orange-700 border-orange-200 bg-orange-50',
                rose: 'text-rose-700 border-rose-200 bg-rose-50',
                pink: 'text-pink-700 border-pink-200 bg-pink-50',
                modern: 'text-slate-800 border-slate-300 bg-white'
            },
            size: {
                sm: 'text-xs py-0.5 px-2',
                md: 'text-sm py-0.5 px-2.5',
                lg: 'text-sm py-1 px-3'
            }
        }
    })({
        size: props.size,
        type: props.type,
        color: props.color
    })
)
</script>

<template>
    <div :class="customStyles" data-testid="chargemap-b2b-badge">
        <IconDot
            v-if="indicator"
            data-testid="indicator"
            :class="`text-${indicator}-600`"
        />
        <div v-if="$slots.iconLeft" class="w-3 h-3">
            <slot name="iconLeft" />
        </div>
        <slot />
        <div v-if="$slots.iconRight" class="w-3 h-3">
            <slot name="iconRight" />
        </div>
    </div>
</template>
